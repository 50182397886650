import { useEffect, useRef, useState } from 'react';
import { m } from 'framer-motion';
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';
import { useRouter } from 'next/router';
import { useBFContext } from '@context';
import { BUILD_FLOW_STEPS, LENSES_PACKAGES, RX_TYPE_ID } from '@constants';
import { RX_TYPE } from '@utils/constants/base-skus';
import { LOCALE_CODES, useFeatureInLocale } from '@utils/index';
import { RxOption } from '@ts/components';
import { CustomLenses, Flex, LensPackages, PrescriptionLensExpansion, ProgressiveLens } from '@components';

const PrescriptionsLensExpansion = ({ isCartUsability }: { isCartUsability?: boolean }) => {
	const { query } = useRouter();
	const isLowerTierProgressive = useFeatureInLocale('is-lower-tier-progressive', LOCALE_CODES.US);
	const isEditMode = Boolean(query?.edit);
	const { rxType, readerRx, step, rxOptions, lensPackage, setLensPackage, setStep, setSelectedLenses } = useBFContext();
	const packageStepRef = useRef<HTMLDivElement>(null);
	const customizeStepRef = useRef<HTMLDivElement>(null);
	const isReadersSelected = rxType === RX_TYPE.READERS;
	const [lensesStepEditMode, setLensesStepEditMode] = useState(false);
	const [showOptions, setShowOptions] = useState(false);
	const isLensStep = step === BUILD_FLOW_STEPS.LENS;
	const isSubmitStep = step === BUILD_FLOW_STEPS.SUBMIT;
	const isPrescriptionsStep =
		isLensStep ||
		step === BUILD_FLOW_STEPS.PROGRESSIVE ||
		step === BUILD_FLOW_STEPS.PACKAGE ||
		step === BUILD_FLOW_STEPS.CUSTOMIZE ||
		isSubmitStep;

	useEffect(() => {
		if (isReadersSelected && readerRx) {
			if (!lensPackage) setLensPackage(LENSES_PACKAGES.STANDARD);
			if (!isEditMode) setSelectedLenses([]);
			setStep(BUILD_FLOW_STEPS.CUSTOMIZE);
			setLensesStepEditMode(isEditMode);
		}
	}, [rxType, lensPackage, isReadersSelected, readerRx, isEditMode]);

	useEffect(() => {
		if (step === BUILD_FLOW_STEPS.PROGRESSIVE) {
			setShowOptions(true);
		} else if (step === BUILD_FLOW_STEPS.PACKAGE || step === BUILD_FLOW_STEPS.CUSTOMIZE || step === BUILD_FLOW_STEPS.SUBMIT) {
			setTimeout(() => {
				setShowOptions(!!rxType);
			}, 250);
		} else {
			setShowOptions(false);
		}
	}, [rxType, step]);

	if (!isPrescriptionsStep && !isReadersSelected) return null;

	const mappedPrescriptions = () =>
		rxOptions
			.filter(option => option.id !== RX_TYPE_ID.PREMIUM_PROGRESSIVE)
			.map((option: RxOption, index: number) => (
				<PrescriptionLensExpansion key={option.id} option={option} index={index} />
			));

	const progressiveOptions = rxOptions.filter(option => option.id.includes('progressive'));
	const mappedProgressiveOptions = () =>
		progressiveOptions.map((option, index) => <ProgressiveLens key={option.id} option={option} index={index} />);

	if (!isPrescriptionsStep && !isReadersSelected) return null;
	const showCustomLensesStep =
		(isEditMode && isCartUsability) || step === BUILD_FLOW_STEPS.CUSTOMIZE[step]
			? !!lensPackage && !!rxType && lensesStepEditMode
			: !!lensPackage && !!rxType;

	return (
		<RadioGroupPrimitive.Root asChild value={rxType}>
			<>
				{!showOptions && mappedPrescriptions()}
				{/* Added just for illusion of coming back to LENS step, maybe there's a better way to do this*/}
				{step === BUILD_FLOW_STEPS.LENS && !!rxType && !isCartUsability && !isLowerTierProgressive && (
					<>
						<LensPackages ref={packageStepRef} />
						{!!lensPackage && <CustomLenses ref={customizeStepRef} />}
					</>
				)}
				{step === BUILD_FLOW_STEPS.PROGRESSIVE && mappedProgressiveOptions()}
				{showOptions && step !== BUILD_FLOW_STEPS.PROGRESSIVE && step !== BUILD_FLOW_STEPS.LENS && !!rxType && (
					<Flex column>
						<PrescriptionLensExpansion option={rxOptions.find(option => option.optionValue === rxType)} index={0} />
						<m.div
							variants={{
								hidden: {
									opacity: 0,
								},
								show: {
									opacity: 1,
								},
							}}
							initial='hidden'
							animate='show'
							transition={{ type: 'spring', delay: 0.5 }}
						>
							<Flex column>
								{rxType && !isReadersSelected && (
									<LensPackages
										ref={packageStepRef}
										callback={isCartUsability ? () => setLensesStepEditMode(true) : undefined}
									/>
								)}
								{rxType && isReadersSelected && readerRx && <LensPackages ref={packageStepRef} />}
								{showCustomLensesStep && <CustomLenses ref={customizeStepRef} />}
							</Flex>
						</m.div>
					</Flex>
				)}
			</>
		</RadioGroupPrimitive.Root>
	);
};

export default PrescriptionsLensExpansion;
