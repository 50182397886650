import { useRef } from 'react';
import { m } from 'framer-motion';
import cn from 'classnames';
import { useRouter } from 'next/router';
import { useBFContext } from '@context';
import { getVariantPriceDiff } from '@services/shopify/hooks/useBaseFrameVariant';
import { BASE_FRAME_LENS_OPTIONS, RX_TYPE } from '@utils/constants/base-skus';
import { BUILD_FLOW_STEP_DIRECTION, BUILD_FLOW_STEPS, CUSTOMER_JOURNEYS, LOCALE_DICT } from '@constants';
import { buildFlowPrescriptionVariantsLensExpansion } from '@utils/motions';
import { RxOption } from '@ts/components';
import { LOCALE_CODES, useFeatureInLocale } from '@utils/index';
import RxOptions from './RxOptions';
import ReadersStrength from './ReadersStrength';
import styles from './PrescriptionLensExpansion.module.scss';

type PrescriptionProps = {
	option: RxOption;
	index: number;
};

const PrescriptionLensExpansion = ({ option, index }: PrescriptionProps) => {
	const {
		setRxType,
		readerRx,
		rxType,
		step,
		journey,
		variantPrices,
		BaseFrameVariant,
		setStepChange,
		setStep,
		setLensPackage,
		setSelectedLenses,
	} = useBFContext();
	const { locale } = useRouter();
	const ref = useRef<HTMLDivElement>();
	const isLowerTierProgressive = useFeatureInLocale('is-lower-tier-progressive', LOCALE_CODES.US);
	const isPrescriptionStep = step === BUILD_FLOW_STEPS.LENS;
	const isCustomizeOrPackageStep = step === BUILD_FLOW_STEPS.CUSTOMIZE || step === BUILD_FLOW_STEPS.PACKAGE;
	const isReadersSelected = rxType === RX_TYPE.READERS;
	const isSunLens = journey === CUSTOMER_JOURNEYS.SUNGLASSES;
	const isProgressiveOption = option.optionValue === RX_TYPE.PROGRESSIVE;
	const isPremiumProgressiveOption = option.optionValue === RX_TYPE.PREMIUM_PROGRESSIVE;

	const isOptionSelected = rxType === option.optionValue;
	const showReaderStrength = isReadersSelected && isOptionSelected && !isPrescriptionStep;

	const variantPriceDiff = getVariantPriceDiff({
		lookup: variantPrices,
		current: BaseFrameVariant,
		rxType: option.optionValue,
		sunLensException: isSunLens,
		locale,
		currencyCode: LOCALE_DICT[locale].currencyCode,
	});

	const classes = cn(styles['rx-list-item'], {
		[styles['hover-disabled']]: isCustomizeOrPackageStep,
		[styles['is-readers']]: isReadersSelected,
	});

	let animate = 'initial';
	let initial = 'initial';
	if (isCustomizeOrPackageStep) {
		animate = isOptionSelected && (isReadersSelected || !isSunLens) ? 'active' : 'inactive';
		initial = isOptionSelected ? 'active' : 'inactive';
	}

	const useOpacity = isReadersSelected ? !!readerRx : !!rxType;

	return (
		<>
			<m.div
				ref={ref}
				key={option.id}
				id={option.id}
				initial={initial}
				data-prescription-type={option.copy}
				animate={animate}
				variants={buildFlowPrescriptionVariantsLensExpansion(index, !!ref?.current ? ref?.current.offsetTop : 0)}
				transition={{ type: 'tween', duration: 0.25 }}
				custom={index}
				onClick={() => {
					// TODO: refactor nested ifs and logic
					if (isLowerTierProgressive && isProgressiveOption && isPrescriptionStep) {
						setStep(BUILD_FLOW_STEPS.PROGRESSIVE);
					} else if (!rxType || rxType !== option.optionValue) {
						setRxType(option.optionValue);
						setLensPackage(null);
						setSelectedLenses([BASE_FRAME_LENS_OPTIONS.CR39]);
						if (!rxType || rxType !== option.optionValue) {
							!isProgressiveOption
								? setStepChange(BUILD_FLOW_STEP_DIRECTION.NEXT)
								: setStep(BUILD_FLOW_STEPS.PACKAGE);
						}
					} else if (!!rxType && rxType === option.optionValue && isPrescriptionStep) {
						isLowerTierProgressive
							? setStepChange(BUILD_FLOW_STEP_DIRECTION.NEXT)
							: setStep(BUILD_FLOW_STEPS.PACKAGE);
					} else if (isCustomizeOrPackageStep) {
						if (isLowerTierProgressive && (isProgressiveOption || isPremiumProgressiveOption))
							setStep(BUILD_FLOW_STEPS.PROGRESSIVE);
						else setStep(BUILD_FLOW_STEPS.LENS);
					}
				}}
			>
				<m.div
					className={classes}
					initial={initial}
					animate={animate}
					variants={{ active: { opacity: useOpacity ? 0.5 : 1 } }}
					transition={{ type: 'spring', delay: 0.5 }}
				>
					<RxOptions option={option} variantPriceDiff={variantPriceDiff} />
					{showReaderStrength && <ReadersStrength />}
				</m.div>
			</m.div>
		</>
	);
};

export default PrescriptionLensExpansion;
