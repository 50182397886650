import cn from 'classnames';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { Checkmark, TypographyButton } from '@components';
import { acetylateMetalColors, capitalize } from '@utils/strings';
import { FRAME_COLOR_IMAGE_LINKS, REFINEMENT_FILTER_COLORS } from '@constants';
import { useFilterContext } from '@context';
import styles from './RefinementSwatch.module.scss';
import { useTranslation } from '@utils/index';

const getTranslatedTexts = translator => {
	return {
		colorWhite: translator('color-white'),
		colorYellow: translator('color-yellow'),
	};
};

const RefinementSwatch = ({ label, isRefined, refine, type: refinementType, value }) => {
	const { type } = useFilterContext();
	const isColorRefresh = useFeatureIsOn('is-color-refresh-2');
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);
	const swatchClasses = cn(styles.swatch, {
		[styles.rainbow]: label.toUpperCase() === 'RAINBOW',
		[styles['image']]: FRAME_COLOR_IMAGE_LINKS[label],
	});

	const checkmarkClasses = cn(styles.checkmark, {
		[styles.checkmarkBlack]:
			label.includes('White') ||
			label.includes('Yellow') ||
			label.includes(translations.colorWhite) ||
			label.includes(translations.colorYellow),
	});
	const labelWithoutSun = label.replace('Sun - ', '');

	const swatchColor = REFINEMENT_FILTER_COLORS[(labelWithoutSun as string).toUpperCase()];
	if (swatchColor) {
		const dataTags = {
			'data-filter-refinement-option': label,
			[`data-bf-filter-${refinementType}`]: value,
		};

		return (
			<li
				key={`refinement-swatch-${label}`}
				className={cn(styles.container, { [styles['buildflow']]: type === 'buildflow' })}
				onClick={() => {
					refine(value);
				}}
				role='button'
				title={label}
				{...dataTags}
			>
				<div className={styles.wrapper}>
					<button
						className={swatchClasses}
						style={{ backgroundColor: swatchColor }}
						data-color={labelWithoutSun}
						title={acetylateMetalColors(label, isColorRefresh)}
						data-image-label={label}
					>
						{isRefined && <Checkmark className={checkmarkClasses} />}
					</button>
				</div>
				<TypographyButton small className={styles['label']}>
					{capitalize(acetylateMetalColors(labelWithoutSun, isColorRefresh), true)}
				</TypographyButton>
			</li>
		);
	}
};

export default RefinementSwatch;
