import { useRouter } from 'next/router';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { FrameFiltering } from '@components';
import { CUSTOMER_JOURNEYS, MEDIUM_MAX_WIDTH } from '@constants';
import { COLLECTION_LISTS } from '@constants/contentful';
import { useBFContext } from '@context';
import { getAngoliaRefinementBuildFlow } from '@utils/algolia';
import { useIsMobile } from '@utils/hooks';

const CollectionStep__BFFiltering = () => {
	const { frame, journey } = useBFContext();
	const isFilterOutline = useFeatureIsOn('is-filter-outline-button');
	const isFilterTags = useFeatureIsOn('is-filter-tags');
	const isAlwaysOpen = useFeatureIsOn('is-always-filter-tops');
	const isChooseTopsHover = useFeatureIsOn('is-choose-tops-hover');
	const isMobile = useIsMobile({ maxWidth: MEDIUM_MAX_WIDTH });
	const { locale } = useRouter();

	return (
		<FrameFiltering
			type='bf-all-tops'
			collectionList={COLLECTION_LISTS.BUILD_FLOW}
			refinementList={getAngoliaRefinementBuildFlow(locale)}
			frame={frame}
			isSunglassesRoute={journey === CUSTOMER_JOURNEYS.SUNGLASSES}
			disableHeader={isFilterOutline || isAlwaysOpen || (isChooseTopsHover && isMobile) || isFilterTags}
		/>
	);
};

export default CollectionStep__BFFiltering;
