import { cloneElement, memo, ReactElement, useCallback, useRef, useState } from 'react';
import { nanoid } from 'nanoid';
import { AnimatePresence, m, useReducedMotion } from 'framer-motion';
import { useRouter } from 'next/router';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import cn from 'classnames';
import { ButtonProps, Caption, Close, Flex, LineItemCard, MyTops } from '@components';
import { useBFContext } from '@utils/context';
import { getMicrocartAnimation } from '@utils/motions';
import { useClickOutside, useTranslation } from '@utils/hooks';
import { NormalizedVariant } from '@ts/product';
import { BUILD_FLOW_STEP_DIRECTION } from '@constants';
import { useCart } from '@services/shopify';
import { BUNDLE_KEY_QUERY } from '@utils/constants/cart';
import styles from './MicrocartQuickAdd.module.scss';

interface MicrocartQuickAddProps {
	triggerButton?: ReactElement;
	tops: {
		[key: string]: NormalizedVariant;
	};
	onTrashClick?: (top: NormalizedVariant) => void;
	buttonProps?: ButtonProps;
}
const getTranslatedTitles = translator => {
	return {
		myTopFrame: translator('my-top-frames'),
		myBaseFrame: translator('my-base-frame'),
		closeMicrocartPopup: translator('close-microcart-popup'),
	};
};

const MicrocartQuickAdd = ({ triggerButton, tops, onTrashClick }: MicrocartQuickAddProps) => {
	const { translator } = useTranslation();
	const microcartRef = useRef(null);
	const triggerButtonRef = useRef(null);
	const { data: cart } = useCart();
	const { isQuickAdd, setStepChange } = useBFContext();
	const isFilterOutline = useFeatureIsOn('is-filter-outline-button');
	const isFilterTags = useFeatureIsOn('is-filter-tags');

	const isAlwaysOpen = useFeatureIsOn('is-always-filter-tops');
	const { query, push, asPath } = useRouter();
	const prefersReducedMotion = useReducedMotion();
	const [showMicrocart, setShowMicrocart] = useState(false);
	const callback = useCallback(() => setShowMicrocart(value => !value), []);
	const titles = getTranslatedTitles(translator);
	useClickOutside(microcartRef, () => setShowMicrocart(false), false, triggerButtonRef);
	const savedBundle = cart?.bundles && query[BUNDLE_KEY_QUERY] ? cart?.bundles[`${query[BUNDLE_KEY_QUERY]}`] : null;
	const baseFrameQuickAdd = savedBundle?.base?.frame;
	const microCartClasses = cn(styles['microcart'], {
		[styles['microcart--is-filter']]: isFilterOutline || isAlwaysOpen || isFilterTags,
	});

	return (
		<div className={styles['container']} data-microcart-open={showMicrocart} data-testid='microcart'>
			{cloneElement(triggerButton, {
				onClick: callback,
				className: styles['button'],
				ref: triggerButtonRef,
			})}
			<AnimatePresence>
				{showMicrocart && (
					<m.div
						className={microCartClasses}
						ref={microcartRef}
						{...getMicrocartAnimation(prefersReducedMotion, isQuickAdd)}
					>
						<div className={styles['microcart-container']}>
							<div className={styles['caret']} />
							<Flex fullWidth align='center' className={styles['header']} justify='end'>
								<Close
									label={titles.closeMicrocartPopup}
									onClick={callback}
									data-microcart-close
									height={12}
									width={12}
								/>
							</Flex>
							<Flex className={styles['scrollable']} column fullWidth gap={3}>
								{baseFrameQuickAdd && (
									<Flex gap={3} fullWidth column>
										<Caption large className={styles.topFrameTitle}>
											{titles.myBaseFrame}
										</Caption>
										<LineItemCard
											key={`line-${query[BUNDLE_KEY_QUERY] ?? nanoid()}`}
											data={baseFrameQuickAdd}
											dataSource={'shopify'}
											optimistic
											onDelete={() => {
												setStepChange(BUILD_FLOW_STEP_DIRECTION.PREV);
												const cleanPath = asPath.split('?')[0];
												push(cleanPath, undefined, { shallow: true });
											}}
											showTags
											noInteraction
											activePrimaryAction
										/>
									</Flex>
								)}
								<Caption large className={styles.topFrameTitle}>
									{titles.myTopFrame}
								</Caption>
								<MyTops
									tops={tops}
									customComponent={top => (
										<LineItemCard
											key={`line-${top.id}`}
											data={{
												variant: top,
												properties: {},
												optimistic: true,
												discountAllocations: [],
												id: top.id,
												quantity: 1,
											}}
											dataSource={'shopify'}
											deletionCallback={() => onTrashClick(top)}
											showTags
											noInteraction
											activePrimaryAction
											showDescription={false}
										/>
									)}
								/>
							</Flex>
						</div>
					</m.div>
				)}
			</AnimatePresence>
		</div>
	);
};

export default memo(MicrocartQuickAdd);
