import { useRef } from 'react';
import { m } from 'framer-motion';
import cn from 'classnames';
import { useRouter } from 'next/router';
import { useBFContext } from '@context';
import { getVariantPriceDiff } from '@services/shopify/hooks/useBaseFrameVariant';
import { RX_TYPE } from '@utils/constants/base-skus';
import { BUILD_FLOW_STEP_DIRECTION, BUILD_FLOW_STEPS, CUSTOMER_JOURNEYS, LOCALE_DICT } from '@constants';
import { buildFlowPrescriptionVariantsLensExpansion } from '@utils/motions';
import { RxOption } from '@ts/components';
import RxOptions from '../PrescriptionLensExpansion/RxOptions';
import ReadersStrength from '../PrescriptionLensExpansion/ReadersStrength';
import styles from '../PrescriptionLensExpansion/PrescriptionLensExpansion.module.scss';

type PrescriptionProps = {
	option: RxOption;
	index: number;
};

const ProgressiveLens = ({ option, index }: PrescriptionProps) => {
	const { setRxType, readerRx, rxType, step, journey, variantPrices, BaseFrameVariant, setStepChange } = useBFContext();
	const { locale } = useRouter();
	const ref = useRef<HTMLDivElement>();
	const isPrescriptionStep = step === BUILD_FLOW_STEPS.LENS;
	const isLensesStep = step === BUILD_FLOW_STEPS.CUSTOMIZE || step === BUILD_FLOW_STEPS.PACKAGE;
	const isReadersSelected = rxType === RX_TYPE.READERS;
	const isSunLens = journey === CUSTOMER_JOURNEYS.SUNGLASSES;

	const isOptionSelected = rxType === option.optionValue;
	const showReaderStrength = isReadersSelected && isOptionSelected && !isPrescriptionStep;

	const variantPriceDiff = getVariantPriceDiff({
		lookup: variantPrices,
		current: BaseFrameVariant,
		rxType: option.optionValue,
		sunLensException: isSunLens,
		locale,
		currencyCode: LOCALE_DICT[locale].currencyCode,
	});

	const classes = cn(styles['rx-list-item'], {
		[styles['hover-disabled']]: isLensesStep,
		[styles['is-readers']]: isReadersSelected,
	});

	let animate = 'initial';
	let initial = 'initial';
	if (isLensesStep) {
		animate = isOptionSelected && (isReadersSelected || !isSunLens) ? 'active' : 'inactive';
		initial = isOptionSelected ? 'active' : 'inactive';
	}

	const useOpacity = isReadersSelected ? !!readerRx : !!rxType;

	return (
		<>
			<m.div
				ref={ref}
				key={option.id}
				id={option.id}
				initial={initial}
				data-prescription-type={option.copy}
				animate={animate}
				variants={buildFlowPrescriptionVariantsLensExpansion(index, !!ref?.current ? ref?.current.offsetTop : 0)}
				transition={{ type: 'tween', duration: 0.25 }}
				custom={index}
				onClick={() => {
					setStepChange(BUILD_FLOW_STEP_DIRECTION.NEXT);
					setRxType(option.optionValue);
				}}
			>
				<m.div
					className={classes}
					initial={initial}
					animate={animate}
					variants={{ active: { opacity: useOpacity ? 0.5 : 1 } }}
					transition={{ type: 'spring', delay: 0.5 }}
				>
					<RxOptions option={option} variantPriceDiff={variantPriceDiff} />
					{showReaderStrength && <ReadersStrength />}
				</m.div>
			</m.div>
		</>
	);
};

export default ProgressiveLens;
