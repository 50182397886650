/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { COLLECTION_LISTS } from '@constants/contentful';
import {
	BASE_FRAME_NAMES,
	CUSTOMER_JOURNEYS,
	DEFAULT_CATEGORIES,
	DEFAULT_DEMOS,
	LOCALE_CODES,
	LOCALE_DICT,
	NEW_BASE_FRAME_NAMES,
} from '@constants';
import { ALL_BASE_FRAME_HANDLES, createRxOptionsForJourney, generateLifestyleImages, getGlobalProps } from '@utils/index';
import { getProducts } from '@services/shopify';
import { normalizeContentfulEntry, transformCollectionsToProducts } from '@utils/normalizers';
import { fetchContentful } from '@services/contentful/client';
import { BuildFlowData, CopyFields, DemoListFields, PrescriptionOption } from '@ts/contentful';
import { getStars } from '@services/yotpo';
import { NormalizedCollection, NormalizedProduct, NormalizedVariant } from '@ts/product';
import { getMultipleCollections } from '@services/shopify/operations/get-multiple-collections';
import { Image } from '@ts/shopify-storefront-api';
import { getAllBaseFrames } from '@services/contentful';
import { fetchAllBaseFrameVariants } from '@services/shopify/hooks/useBaseFrameVariant';
import { RxOption } from '@ts/index';
import { BASE_FRAME_LENS_OPTIONS, BaseVariantPriceDictionary, RX_TYPE } from './constants/base-skus';

const HARD_CODED_PRESCRIPTIONS: Array<PrescriptionOption> = [
	{
		locale: 'en-US',
		metadata: { tags: [] },
		contentType: 'bfLensPrescription',
		componentType: 'bfLensPrescription',
		internalName: 'Single Vision',
		type: 'Single Vision' as RX_TYPE,
		upgrades: [
			{
				locale: 'en-US',
				metadata: { tags: [] },
				contentType: 'bfLensUpgrade',
				componentType: 'bfLensUpgrade',
				internalName: 'Light Responsive',
				handle: 'light-responsive',
				type: 'Light Responsive' as BASE_FRAME_LENS_OPTIONS,
				tag: 'Most Convenient',
				copy: {
					locale: 'en-US',
					metadata: { tags: [] },
					contentType: 'copy',
					componentType: 'copy',
					internalName: 'Light Responsive',
					slug: 'light-responsive',
					title: 'Light Responsive',
					description: 'Darkens or lightens in response to UV rays and has blue-light protection.',
				} as CopyFields,
				altCopy: {
					locale: 'en-US',
					metadata: { tags: [] },
					contentType: 'copy',
					componentType: 'copy',
					internalName: 'Light Responsive Alt',
					slug: 'light-responsive-alt',
					title: 'Light Responsive',
					description: 'Darkens or lightens in response to UV rays and has blue-light protection.',
				} as CopyFields,
			},
			{
				locale: 'en-US',
				metadata: { tags: [] },
				contentType: 'bfLensUpgrade',
				componentType: 'bfLensUpgrade',
				internalName: 'Blue Light',
				type: 'Blue Light' as BASE_FRAME_LENS_OPTIONS,
				handle: 'blue-light-filtering',
				tag: '#1 Best Seller',
				copy: {
					locale: 'en-US',
					metadata: { tags: [] },
					contentType: 'copy',
					componentType: 'copy',
					internalName: 'Blue-Light Filtering',
					slug: 'blue-light-filtering',
					title: 'Blue-Light Filtering',
					description: 'Filters out blue-light rays emitted from devices and the sun.',
				} as CopyFields,
				altCopy: {
					locale: 'en-US',
					metadata: { tags: [] },
					contentType: 'copy',
					componentType: 'copy',
					internalName: 'Blue-Light Filtering Alt',
					slug: 'blue-light-filtering-alt',
					title: 'Blue-Light Filtering',
					description: 'Filters out blue-light rays emitted from devices and the sun.',
				} as CopyFields,
			},
			{
				locale: 'en-US',
				metadata: { tags: [] },
				contentType: 'bfLensUpgrade',
				componentType: 'bfLensUpgrade',
				internalName: 'Premium Plus',
				type: 'Premium Plus' as BASE_FRAME_LENS_OPTIONS,
				handle: 'premium-ultra-thin',
				tag: 'Popular Upgrade',
				copy: {
					locale: 'en-US',
					metadata: { tags: [] },
					contentType: 'copy',
					componentType: 'copy',
					internalName: 'Premium Plus',
					slug: 'premium-plus',
					title: 'Premium Plus',
					description: 'Up to 20% thinner than our standard lenses, ideal for higher prescriptions.',
				} as CopyFields,
				altCopy: {
					locale: 'en-US',
					metadata: { tags: [] },
					contentType: 'copy',
					componentType: 'copy',
					internalName: 'Premium Plus Alt',
					slug: 'premium-plus-alt',
					title: 'Premium Plus Alt',
					description: 'Up to 20% thinner than our standard lenses, ideal for higher prescriptions. Alt',
				} as CopyFields,
			},
		],
		copy: {
			locale: 'en-US',
			metadata: { tags: [] },
			contentType: 'copy',
			componentType: 'copy',
			internalName: 'Single Vision',
			slug: 'single-vision',
			title: 'Single Vision',
			description: 'Lenses that help you see near or far distances.',
		} as CopyFields,
		altCopy: {
			locale: 'en-US',
			metadata: { tags: [] },
			contentType: 'copy',
			componentType: 'copy',
			internalName: 'Single Vision Alt',
			slug: 'single-vision-alt',
			title: 'Single Vision',
			description: 'Lenses that help you see near or far distances.',
		} as CopyFields,
	},
	{
		locale: 'en-US',
		metadata: { tags: [] },
		contentType: 'bfLensPrescription',
		componentType: 'bfLensPrescription',
		internalName: 'Progressives',
		type: 'Progressive' as RX_TYPE,
		upgrades: [
			{
				locale: 'en-US',
				metadata: { tags: [] },
				contentType: 'bfLensUpgrade',
				componentType: 'bfLensUpgrade',
				internalName: 'Light Responsive',
				type: 'Light Responsive' as BASE_FRAME_LENS_OPTIONS,
				handle: 'light-responsive',
				tag: 'Most Convenient',
				copy: {
					locale: 'en-US',
					metadata: { tags: [] },
					contentType: 'copy',
					componentType: 'copy',
					internalName: 'Light Responsive',
					slug: 'light-responsive',
					title: 'Light Responsive',
					description: 'Darkens or lightens in response to UV rays and has blue-light protection.',
				} as CopyFields,
				altCopy: {
					locale: 'en-US',
					metadata: { tags: [] },
					contentType: 'copy',
					componentType: 'copy',
					internalName: 'Light Responsive Alt',
					slug: 'light-responsive-alt',
					title: 'Light Responsive',
					description: 'Darkens or lightens in response to UV rays and has blue-light protection.',
				} as CopyFields,
			},
			{
				locale: 'en-US',
				metadata: { tags: [] },
				contentType: 'bfLensUpgrade',
				componentType: 'bfLensUpgrade',
				internalName: 'Blue Light',
				type: 'Blue Light' as BASE_FRAME_LENS_OPTIONS,
				handle: 'blue-light-filtering',
				tag: '#1 Best Seller',
				copy: {
					locale: 'en-US',
					metadata: { tags: [] },
					contentType: 'copy',
					componentType: 'copy',
					internalName: 'Blue-Light Filtering',
					slug: 'blue-light-filtering',
					title: 'Blue-Light Filtering',
					description: 'Filters out blue-light rays emitted from devices and the sun.',
				} as CopyFields,
				altCopy: {
					locale: 'en-US',
					metadata: { tags: [] },
					contentType: 'copy',
					componentType: 'copy',
					internalName: 'Blue-Light Filtering Alt',
					slug: 'blue-light-filtering-alt',
					title: 'Blue-Light Filtering',
					description: 'Filters out blue-light rays emitted from devices and the sun.',
				} as CopyFields,
			},
			{
				locale: 'en-US',
				metadata: { tags: [] },
				contentType: 'bfLensUpgrade',
				componentType: 'bfLensUpgrade',
				internalName: 'Premium Plus',
				type: 'Premium Plus' as BASE_FRAME_LENS_OPTIONS,
				handle: 'premium-ultra-thin',
				tag: 'Popular Upgrade',
				copy: {
					locale: 'en-US',
					metadata: { tags: [] },
					contentType: 'copy',
					componentType: 'copy',
					internalName: 'Premium Plus',
					slug: 'premium-plus',
					title: 'Premium Plus',
					description: 'Up to 20% thinner than our standard lenses, ideal for higher prescriptions.',
				} as CopyFields,
				altCopy: {
					locale: 'en-US',
					metadata: { tags: [] },
					contentType: 'copy',
					componentType: 'copy',
					internalName: 'Premium Plus Alt',
					slug: 'premium-plus-alt',
					title: 'Premium Plus Alt',
					description: 'Up to 20% thinner than our standard lenses, ideal for higher prescriptions. Alt',
				} as CopyFields,
			},
		],
		copy: {
			locale: 'en-US',
			metadata: { tags: [] },
			contentType: 'copy',
			componentType: 'copy',
			internalName: 'Progressive Lenses',
			slug: 'progressive-lenses',
			title: 'Progressive Lenses',
			description: 'Lenses that help you see near, far, and in-between, replacing the need for multiple frames.\n',
		} as CopyFields,
		altCopy: {
			locale: 'en-US',
			metadata: { tags: [] },
			contentType: 'copy',
			componentType: 'copy',
			internalName: 'Progressive Lenses Alt',
			slug: 'progressive-lenses-alt',
			title: 'Progressive Lenses',
			description: 'Lenses that help you see near, far, and in-between, replacing the need for multiple frames.',
		} as CopyFields,
	},
	{
		locale: 'en-US',
		metadata: { tags: [] },
		contentType: 'bfLensPrescription',
		componentType: 'bfLensPrescription',
		internalName: 'Readers',
		type: 'Readers' as RX_TYPE,
		upgrades: [
			{
				locale: 'en-US',
				metadata: { tags: [] },
				contentType: 'bfLensUpgrade',
				componentType: 'bfLensUpgrade',
				internalName: 'Light Responsive',
				type: 'Light Responsive' as BASE_FRAME_LENS_OPTIONS,
				handle: 'light-responsive',
				tag: 'Most Convenient',
				copy: {
					locale: 'en-US',
					metadata: { tags: [] },
					contentType: 'copy',
					componentType: 'copy',
					internalName: 'Light Responsive',
					slug: 'light-responsive',
					title: 'Light Responsive',
					description: 'Darkens or lightens in response to UV rays and has blue-light protection.',
				} as CopyFields,
				altCopy: {
					locale: 'en-US',
					metadata: { tags: [] },
					contentType: 'copy',
					componentType: 'copy',
					internalName: 'Light Responsive Alt',
					slug: 'light-responsive-alt',
					title: 'Light Responsive',
					description: 'Darkens or lightens in response to UV rays and has blue-light protection.',
				} as CopyFields,
			},
			{
				locale: 'en-US',
				metadata: { tags: [] },
				contentType: 'bfLensUpgrade',
				componentType: 'bfLensUpgrade',
				internalName: 'Blue Light',
				type: 'Blue Light' as BASE_FRAME_LENS_OPTIONS,
				handle: 'blue-light-filtering',
				tag: '#1 Best Seller',
				copy: {
					locale: 'en-US',
					metadata: { tags: [] },
					contentType: 'copy',
					componentType: 'copy',
					internalName: 'Blue-Light Filtering',
					slug: 'blue-light-filtering',
					title: 'Blue-Light Filtering',
					description: 'Filters out blue-light rays emitted from devices and the sun.',
				} as CopyFields,
				altCopy: {
					locale: 'en-US',
					metadata: { tags: [] },
					contentType: 'copy',
					componentType: 'copy',
					internalName: 'Blue-Light Filtering Alt',
					slug: 'blue-light-filtering-alt',
					title: 'Blue-Light Filtering',
					description: 'Filters out blue-light rays emitted from devices and the sun.',
				} as CopyFields,
			},
		],
		copy: {
			locale: 'en-US',
			metadata: { tags: [] },
			contentType: 'copy',
			componentType: 'copy',
			internalName: 'Readers',
			slug: 'readers',
			title: 'Readers',
			description: 'Lenses that help you see up-close text or objects more clearly.',
		} as CopyFields,
		altCopy: {
			locale: 'en-US',
			metadata: { tags: [] },
			contentType: 'copy',
			componentType: 'copy',
			internalName: 'Readers Alt',
			slug: 'readers-alt',
			title: 'Readers',
			description: 'Lenses that help you see up-close text or objects more clearly.',
		} as CopyFields,
	},
	{
		locale: 'en-US',
		metadata: { tags: [] },
		contentType: 'bfLensPrescription',
		componentType: 'bfLensPrescription',
		internalName: 'Non Prescription',
		type: 'Non-RX' as RX_TYPE,
		upgrades: [
			{
				locale: 'en-US',
				metadata: { tags: [] },
				contentType: 'bfLensUpgrade',
				componentType: 'bfLensUpgrade',
				internalName: 'Light Responsive',
				type: 'Light Responsive' as BASE_FRAME_LENS_OPTIONS,
				handle: 'light-responsive',
				tag: 'Most Convenient',
				copy: {
					locale: 'en-US',
					metadata: { tags: [] },
					contentType: 'copy',
					componentType: 'copy',
					internalName: 'Light Responsive',
					slug: 'light-responsive',
					title: 'Light Responsive',
					description: 'Darkens or lightens in response to UV rays and has blue-light protection.',
				} as CopyFields,
				altCopy: {
					locale: 'en-US',
					metadata: { tags: [] },
					contentType: 'copy',
					componentType: 'copy',
					internalName: 'Light Responsive Alt',
					slug: 'light-responsive-alt',
					title: 'Light Responsive',
					description: 'Darkens or lightens in response to UV rays and has blue-light protection.',
				} as CopyFields,
			},
			{
				locale: 'en-US',
				metadata: { tags: [] },
				contentType: 'bfLensUpgrade',
				componentType: 'bfLensUpgrade',
				internalName: 'Blue Light',
				type: 'Blue Light' as BASE_FRAME_LENS_OPTIONS,
				handle: 'blue-light-filtering',
				tag: '#1 Best Seller',
				copy: {
					locale: 'en-US',
					metadata: { tags: [] },
					contentType: 'copy',
					componentType: 'copy',
					internalName: 'Blue-Light Filtering',
					slug: 'blue-light-filtering',
					title: 'Blue-Light Filtering',
					description: 'Filters out blue-light rays emitted from devices and the sun.',
				} as CopyFields,
				altCopy: {
					locale: 'en-US',
					metadata: { tags: [] },
					contentType: 'copy',
					componentType: 'copy',
					internalName: 'Blue-Light Filtering Alt',
					slug: 'blue-light-filtering-alt',
					title: 'Blue-Light Filtering',
					description: 'Filters out blue-light rays emitted from devices and the sun.',
				} as CopyFields,
			},
		],
		copy: {
			locale: 'en-US',
			metadata: { tags: [] },
			contentType: 'copy',
			componentType: 'copy',
			internalName: 'Non-Prescription',
			slug: 'non-prescription',
			title: 'Non-Prescription',
			description: 'Lenses without vision correction that are simply for style.',
		} as CopyFields,
		altCopy: {
			locale: 'en-US',
			metadata: { tags: [] },
			contentType: 'copy',
			componentType: 'copy',
			internalName: 'Non-Prescription Alt',
			slug: 'non-prescription-alt',
			title: 'Non-Prescription',
			description: 'Lenses without vision correction that are simply for style.',
		} as CopyFields,
	},
];

const HARD_CODED_MATERIALS = [
	{
		locale: 'en-US',
		metadata: { tags: [] },
		contentType: 'lensMaterial',
		componentType: 'lensMaterial',
		internalName: 'Basic',
		copy: {
			locale: 'en-US',
			contentType: 'copy',
			componentType: 'copy',
			internalName: 'Basic',
			slug: 'basicLens',
			title: 'Standard',
			description: 'Lenses with optical clarity.',
		},
		altCopy: {
			locale: 'en-US',
			contentType: 'copy',
			componentType: 'copy',
			internalName: 'Basic Alt',
			slug: 'basic-alt',
			title: 'Standard',
			description: '1.5 Index Standard Lenses, Scratch Resistant Coating, Anti-Reflective Coating.',
		},
		type: 'Basic',
	},
	{
		locale: 'en-US',
		metadata: { tags: [] },
		contentType: 'lensMaterial',
		componentType: 'lensMaterial',
		internalName: 'Standard',
		copy: {
			locale: 'en-US',

			contentType: 'copy',
			componentType: 'copy',
			internalName: 'Standard',
			slug: 'standard',
			title: 'Plus',
			description: 'Lenses with optical clarity and high-impact resistance.',
		},
		altCopy: {
			locale: 'en-US',

			contentType: 'copy',
			componentType: 'copy',
			internalName: 'Standard Alt',
			slug: 'standard-alt',
			title: 'Plus',
			description:
				'1.59 Index Impact Resistant Lenses up to 22% thinner, Scratch Resistant Coating, UV Protective Coating, Anti-Reflective Coating.',
		},
		type: 'Standard',
	},
	{
		locale: 'en-US',
		metadata: { tags: [] },
		contentType: 'lensMaterial',
		componentType: 'lensMaterial',
		internalName: 'Premium',
		copy: {
			locale: 'en-US',

			contentType: 'copy',
			componentType: 'copy',
			internalName: 'Premium',
			slug: 'premium',
			title: 'Premium',
			description:
				'Lenses with optical clarity up to 35% thinner than basic. Best for high prescriptions (above +3.00 and below -6.00).',
		},
		altCopy: {
			locale: 'en-US',

			contentType: 'copy',
			componentType: 'copy',
			internalName: 'Premium Alt',
			slug: 'premium-alt',
			title: 'Premium',
			description:
				'1.67 High Index Lenses up to 35% thinner, Scratch Resistant Coating, UV Protective Coating, Premium Anti-Reflective Coating. Best for high prescriptions (above +3.00 and below -6.00).',
		},
		type: 'Premium',
	},
];

type GetBaseFrameProps = {
	category: (typeof DEFAULT_CATEGORIES)[number];
	demo: (typeof DEFAULT_DEMOS)[number];
	handle: NEW_BASE_FRAME_NAMES;
	locale: (typeof LOCALE_DICT)[keyof typeof LOCALE_DICT]['label'];
};

type GetBasePLPProps = {
	category: (typeof DEFAULT_CATEGORIES)[number] | 'wider-base-frames';
	demo: (typeof DEFAULT_DEMOS)[number];
	locale: (typeof LOCALE_DICT)[keyof typeof LOCALE_DICT]['label'];
};

export type BaseFrameReturn = {
	bfCollectionListSlug: string;
	product: NormalizedProduct;
	prescriptionOptions: RxOption[];
	lensOptions: any;
	yotpo: { bottomline: any };
	variantPrices: BaseVariantPriceDictionary;
	subscriptionProducts: NormalizedVariant[];
	materialOptions: {
		title: string;
		description: string;
		altTitle: string;
		altDescription: string;
		type: string;
	}[];
};

export async function getBaseFrameProps({ category, demo, handle, locale }: GetBaseFrameProps): Promise<BaseFrameReturn> {
	const { countryCode: country, languageCode: language } = LOCALE_DICT[locale];
	const initialData = await Promise.allSettled([
		getGlobalProps({ locale }),
		getMultipleCollections(handle, { skipImages: false, includeDescription: true, country, language, maxVariants: 64 }),
		fetchContentful({
			'content_type': 'demoList',
			'fields.demo': demo,
			'limit': 1,
			locale,
		}),
		fetchContentful({
			'content_type': 'buildFlowDataV2',
			'fields.slug': 'build-flow-data-v3',
			'limit': 1,
			'include': 3,
			locale,
		}),
		getProducts(['the-tortoise', 'the-white', 'the-black-white-splatter'], { country, language }),
	]);
	const [globalProps, bfCollection, demoList, buildFlowDataToNormalize, subscriptionProducts] = (
		initialData as PromiseFulfilledResult<any>[]
	).map(result => result.value);

	const product = transformCollectionsToProducts([bfCollection])[0];

	const variantPrices = await fetchAllBaseFrameVariants(product.handle + '-black', country, language);

	// TODO: why do we need to cast the result here? smells like an issue with typing in `normalizeContentfulEntry`
	const { materials = HARD_CODED_MATERIALS, prescriptions = HARD_CODED_PRESCRIPTIONS } =
		(await normalizeContentfulEntry<BuildFlowData>(buildFlowDataToNormalize)) as BuildFlowData;

	const prescriptionOptions = createRxOptionsForJourney(prescriptions, category);
	const materialOptions = materials.map(mat => {
		return {
			title: mat.copy.title,
			description: mat.copy.description,
			altTitle: mat.altCopy.title,
			altDescription: mat.altCopy.description,
			type: mat.type,
		};
	});

	const yotpo = await getStars(product.id, product.handle);

	const targetDemo = (await normalizeContentfulEntry(demoList)) as DemoListFields;
	const bfCollectionListSlug = targetDemo?.collectionList?.fields?.slug ?? COLLECTION_LISTS.EVERGREEN;

	const subscriptionsProductVariants = subscriptionProducts?.map(
		product => product.variants.find(variant => variant.option == ALL_BASE_FRAME_HANDLES[handle]) || null
	);

	return {
		...globalProps,
		bfCollectionListSlug,
		prescriptionOptions,
		product,
		yotpo,
		page: 'product',
		variantPrices,
		subscriptionProducts: subscriptionsProductVariants || [],
		materialOptions,
	};
}

export async function getBasePLPProps({ category, demo, locale }: GetBasePLPProps) {
	const { countryCode: country, languageCode: language } = LOCALE_DICT[locale];
	const initialData = await Promise.allSettled([
		getGlobalProps({ locale }),
		fetchContentful({
			'content_type': 'demoList',
			'fields.demo': demo,
			'limit': 1,
			'include': 5,
			locale,
		}),
	]);
	const [globalProps, demoList] = (initialData as PromiseFulfilledResult<any>[]).map(result => result.value);
	if (!demoList) return false;

	const {
		baseFrameProducts,
		hero,
		blueLightHero = undefined,
		sunglassesHero = undefined,
		interactiveCards = [],
		sunglassesInteractiveCards = [],
		seoMetadata,
		seoSunglassesMetadata,
		seoBlueLightMetadata,
		internalName,
		additionalInformation = null,
		sunglassesAdditionalInformation = null,
	} = (await normalizeContentfulEntry(demoList)) as DemoListFields;

	// NOTE: Sets heroData to null if entry is missing or is in DRAFT mode
	let heroData = null;
	let seo = null;
	let additionalInfo = null;
	if (category === CUSTOMER_JOURNEYS.BLUELIGHT) {
		if (seoBlueLightMetadata) seo = seoBlueLightMetadata;
		if (blueLightHero) heroData = blueLightHero;
	} else if (category === CUSTOMER_JOURNEYS.SUNGLASSES) {
		if (seoSunglassesMetadata) seo = seoSunglassesMetadata;
		if (sunglassesHero) heroData = sunglassesHero;
		if (sunglassesAdditionalInformation) additionalInfo = sunglassesAdditionalInformation;
	} else {
		if (hero) heroData = hero;
		if (seoMetadata) seo = seoMetadata;
		if (additionalInformation) additionalInfo = additionalInformation;
	}

	// Base Frame Collection Retrieval
	let collection = (await getAllBaseFrames(
		true,
		{
			skipImages: false,
			country,
			language,
			maxVariants: 64,
		},
		baseFrameProducts
	)) as NormalizedCollection;

	// Remove Mixed-Material Variants
	// MM Base Frames are displayed in separate product cards on PLPs
	collection = {
		...collection,
		products: collection.products.map(product => {
			if (product.handle.includes('mixed-material')) {
				return {
					...product,
					name: product.name.split(' - ')[0],
				};
			}
			return {
				...product,
				variants: product.variants.filter(v => !v.option.includes('Mixed Material')),
			};
		}),
	};

	let isBFSet = true;

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	// Really bad solution for mixed PLPs. "wider-base-frames" isn't really a journey, and it will only have a men and women version
	// So if we're dealing with this one-off PLP, we fetch a hard-coded collection.
	// Long-term usage of mixed PLPs is TBD so will clean this up another time. Hopefully we just kill it.
	if (category === 'wider-base-frames') {
		isBFSet = false;
		collection = await getMultipleCollections(`${demo}s-wider-base-frames`, { country, language });
	}

	const collectionName = isBFSet ? internalName : collection?.name;

	if (!collection) {
		throw new Error(
			`Base Frame Collection not found. Could be issue with Base Frame collection naming convention which should include '-frames' at the end of the handle (ex: mens-frames).`
		);
	}

	return {
		...globalProps,
		seoMetadata: seo,
		collection,
		hero: heroData ? { ...heroData, page: 'plp' } : null,
		background: 'gray1',
		interactiveCards: category === CUSTOMER_JOURNEYS.SUNGLASSES ? sunglassesInteractiveCards : interactiveCards,
		isBFSet,
		collectionName,
		additionalInformation: additionalInfo,
	};
}

export function handleProductPathRedirects(demo: string, locale: string, page: string) {
	if (demo === 'best-seller-bl') {
		return {
			notFound: true,
			revalidate: 600,
		} as const;
	}
	if (demo === 'kids' && locale === LOCALE_CODES.GB) {
		return {
			redirect: {
				destination: `/${locale}/${page}/all`,
				permanent: false,
			},
		};
	}
	return null;
}
