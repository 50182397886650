import dynamic from 'next/dynamic';
import { PRODUCT_TYPES } from '@constants';
import { Button, Modal, NotificationBox, Paragraph, Title } from '@components';
import { Image as ImageType } from '@ts/shopify-storefront-api';
import { FrameSizingData } from '@ts/product';
import variables from '@styles/export.module.scss';
import styles from './About.module.scss';
import { useTranslation } from '@utils/index';

const FrameSizing = dynamic(() => import('@components').then(mod => mod.FrameSizing));

type AboutProps = {
	productType: (typeof PRODUCT_TYPES)[keyof typeof PRODUCT_TYPES];
	description: string;
	descriptionHtml?: string;
	name: string;
	primaryImage?: ImageType;
	frameSizings?: FrameSizingData;
	selectedFrame?: string;
};

const getTranslatedTexts = translator => {
	return {
		aboutProduct: (productName: string) => translator('about-product', { productName }),
		frameSizing: translator('frame-sizing'),
		askAStylist: translator('ask-a-stylist'),
		baseFrameSoldSeparately: translator('base-frame-sold-separately'),
		frameCraftingInstruction: (selectedFrame: string) => translator('frame-crafting-instruction', { selectedFrame }),
	};
};

const About = ({ productType, description, primaryImage, frameSizings, name, selectedFrame }: AboutProps) => {
	const formattedTitle = name.replace('The', 'the');
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);

	return (
		<div className={styles['about']}>
			{productType.includes(PRODUCT_TYPES.BASE_FRAME) && (
				<>
					<Title>{translations.aboutProduct(formattedTitle)}</Title>
					<Paragraph>{description}</Paragraph>
					<div className={styles['buttons']}>
						<Modal>
							<Modal.Trigger asChild>
								<Button color='white' size='medium' fullWidth data-open-fit-guide={name}>
									{translations.frameSizing}
								</Button>
							</Modal.Trigger>
							<Modal.Content removePadding center className={styles['frame-sizing-modal']}>
								<FrameSizing frameSizings={frameSizings} primaryImage={primaryImage} name={name} />
							</Modal.Content>
						</Modal>

						<Button
							color='white'
							size='medium'
							fullWidth
							data-ask-stylist
							// eslint-disable-next-line @typescript-eslint/ban-ts-comment
							// @ts-ignore
							onClick={() => window.dgchat && window.dgchat.methods.launchWidget()}
						>
							{translations.askAStylist}
						</Button>
					</div>
				</>
			)}
			{productType.includes(PRODUCT_TYPES.TOP_FRAME) && (
				<NotificationBox
					closeButton
					title={translations.baseFrameSoldSeparately}
					body={translations.frameCraftingInstruction(selectedFrame)}
					button
					backgroundColor={variables.gray1}
				/>
			)}
			{productType === PRODUCT_TYPES.ACCESSORY && (
				<>
					<Title>{translations.aboutProduct(formattedTitle)}</Title>
					<Paragraph>{description}</Paragraph>
				</>
			)}
		</div>
	);
};

export default About;
